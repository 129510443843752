import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { Util } from '../../utils/util';
import { Calendar } from 'primeng';
@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() name: string = '';
  @Input() customClass: any = '';
  @Input() id;

  @Input() view: string = 'date';
  @Input() dateFormat: string = 'dd/mm/yy';
  @Input() placeholder: string = 'dd/mm/aaaa';

  @Input() maxDate: Date = null;
  @Input() minDate: Date = null;

  @Input() yearRange = '2000:2030';
  @Input() showButtonBar = 'true';

  @Output() onChange = new EventEmitter<any>();
  @Output() onFocusOut = new EventEmitter<any>();

  @ViewChild('datePick') datePickElement: Calendar;

  changeSubscription: Subscription;
  inputModel: Date = null;

  locale = Util.getBrasilLocales();

  constructor() { }

  ngOnInit(): void {
    this.changeSubscription = this.formControl.valueChanges.subscribe(
      (value) => {
        if (value) {
          this.inputModel = Util.formatStringToDate(value);
        } else {
          this.inputModel = undefined;
        }
      }
    );
    this.inputModel = this.formControl.value && Util.formatStringToDate(this.formControl.value);
  }
  ngOnDestroy(): void { }

  get formControl(): FormControl {
    return this.form.get(this.name) as FormControl;
  }

  get invalid(): boolean {
    return this.form.get(this.name).invalid;
  }

  get disabled() {
    return this.formControl.disabled;
  }

  change(value) {
    this.formControl.setValue(this.getValueFormatted(value));
    this.formControl.markAsTouched();
    this.onChange.emit(this.formControl.value);
    this.onFocusOut.emit();
  }

  blur() {
    this.formControl.markAsTouched();
    this.onFocusOut.emit(this.formControl.value);
  }

  getValueFormatted(value: any) {
    return value ? moment(value).format('YYYY-MM-DD HH:mm:SS') : '';
  }
}
